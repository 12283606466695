@import 'bootstrap/dist/css/bootstrap.min.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.max-width {
  max-width: '100%';
}

.noHover{
  pointer-events: none;
}

.bg-primary {
  background-color: #0d6efd !important;
}

.bg-primary:hover {
  background-color: #0b5ed7 !important;
}

.footer-pin-container {
  min-height: calc(5vh - 34px);
}

.footer-pin-child {
  position: relative;
  left: 0;
  bottom: 0;
}

li {
  font-size: 14px;
  margin-left: 10px;
  list-style-type: disc;
}

.li-container {
  padding-top: 10px;
  transform: translateX(20px);
}
